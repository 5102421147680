/* eslint no-console:0 */

import Rails from "rails-ujs";
import $ from "jquery";
import Turbolinks from "turbolinks";
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import * as ActiveStorage from "activestorage";
import "foundation-sites";
import "trix";

// Styles
import "application.scss";

// Images
require.context("../images", true);

const application = Application.start();
const context = require.context("controllers", true, /.js$/);
application.load(definitionsFromContext(context));

Rails.start();
Turbolinks.start();
ActiveStorage.start();

$(document).on("turbolinks:load", function () {
  $(document).foundation();

  $("form").on("click", ".remove_fields", function (event) {
    event.preventDefault();
    $(this).prev("input[type=hidden]").val("1");
    $(this).closest(".add-question__wrapper").hide();
  });

  $("form").on("click", ".add_fields", function (event) {
    event.preventDefault();

    var regexp, time;
    time = new Date().getTime();
    regexp = new RegExp($(this).data("id"), "g");
    $(this).before($(this).data("fields").replace(regexp, time));
  });

  $(".js-callout-close").on("click", function () {
    const $callout = $(this).closest(".callout");
    $callout.remove();
  });
});
