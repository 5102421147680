import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["select", "subscribed", "paid", "unstarted", "all"];

  connect() {
    if (this.get()) {
      this.selectTarget.value = this.get();
    }
  }

  switch(event) {
    const value = event.currentTarget.value;

    if (!value) return;

    this.reset();
    this.set(value);
  }

  reset() {
    this.subscribedTarget.checked = false;
    this.paidTarget.checked = false;
    this.unstartedTarget.checked = false;
    this.allTarget.checked = false;
  }

  set(target) {
    const input = document.querySelector(`[data-input='${target}']`);
    input.checked = true;
  }

  get() {
    if (this.subscribedTarget.checked) {
      return this.subscribedTarget.dataset.input;
    } else if (this.paidTarget.checked) {
      return this.paidTarget.dataset.input;
    } else if (this.unstartedTarget.checked) {
      return this.unstartedTarget.dataset.input;
    } else {
      return this.allTarget.dataset.input;
    }
  }
}
