import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const links = [...this.element.querySelectorAll("a")];

    for (const link of links) {
      link.target = "_blank";
      link.rel = "noreferrer noopener";
    }
  }
}
